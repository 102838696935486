<template>
  <div>
    <div class="container">
      <!-- Title -->
      <div class="page">
        <h4 class="d-flex-center-between  m-0">
          <div class="d-flex-center-middle">
            <div class="d-flex" style="margin: 0 auto; justify-content:center; ">
              <div>
                <div class="exam-title type-title">
                  <div :class="nextTitle ? 'text51' : 'text51'">
                    {{ title }}
                  </div>
                </div>
              </div>
              <div class="exam-title">
                Mocks
              </div>
            </div>
          </div>
          <div class="role-title">
            &nbsp;for&nbsp;
          </div>
          <div class="role-title">
            <router-link :to="{ name: 'LandingPageStudents' }">
              <div class="role-title-button">
                STUDENTS
              </div>
            </router-link>
            <router-link :to="{ name: 'LandingPageEducators' }">
              <div class="role-title-button">
                EDUCATORS
              </div>
            </router-link>
          </div>
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  metaInfo() {
    return {
      title: this.CompanyName
    };
  },

  components: {},

  mixins: [],

  props: [],
  data() {
    return {
      title: "",
      nextTitle: "",
      message: ["SAT", "ACT", "AP", "TOEFL", "IELTS"]
    };
  },
  computed: {},
  watch: {},

  mounted() {
    this.typeAnimation();
  },

  methods: {
    typeAnimation() {
      let index = 0;
      let mIndex = 0;
      let time = 50;
      // clearInterval(this.interval);
      this.interval = setInterval(() => {
        if (index < this.message[mIndex].length) {
          if (index == 0) {
            this.nextTitle = false;
          }
          this.title += this.message[mIndex][index++];
        } else {
          index++;
          this.nextTitle = true;
          if (index == this.message[mIndex].length + 50) {
            this.title = "";
            index = 0;
            mIndex++;
            if (mIndex === this.message.length) {
              mIndex = 0;
            }
          }
        }
      }, time); // 每0.1秒添加一个字符
    }
  }
};
</script>
<style scoped>
.fade-out {
  animation: fadeOut 2s linear infinite;
  animation-fill-mode: forwards; /* 保持最后一帧的状态 */
  animation-iteration-count: 1; /* 动画只播放一次 */
}
.fade-in {
  animation: fadeIn 1s linear infinite;
  animation-fill-mode: forwards; /* 保持最后一帧的状态 */
  animation-iteration-count: 1; /* 动画只播放一次 */
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.text51 {
  width: 100%;
  color: var(--themeColor);
  white-space: nowrap;
  /* color: #5ba5fb; */
  background: linear-gradient(
    269deg,
    #fa12e3 1.83%,
    #7612fa 53.68%,
    #12d0fa 106.48%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
<style scoped>
.page {
  width: 100%;
  height: 720px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--themeColor);
  transition: all 0.3s ease;
  font-family: "Oswald-Bold";
}

.d-flex-center-between {
  display: flex;
  /* display: -webkit-flex; */
  align-items: center;
  justify-content: center;
}


.d-flex-center-middle {
  display: flex;
  /* display: -webkit-flex; */
  align-items: center;
  justify-content: middle;
}
.exam-title,
.role-title,
.exam-title-small {
  color: var(--themeColor);
  font-weight: 700;
  font-size: 6rem;
}
.exam-title,
.role-title {
  line-height: 6rem;
  margin-bottom: 1rem;
}

.role-title-button {
  margin-top: 3rem;
  color: white !important;
  font-size: 3.5rem;
  font-weight: 500;
  background-color: var(--themeColor);
  /* display: inline-block; */
  padding: 1.5rem 5rem;
  border-radius: 1.5rem;
  text-align: center;
  /* background-image: linear-gradient(to right, #5ba5fb, var(--themeColor)); */
  background-image: linear-gradient(to right, #5ba5fb, #d90ceb);
  cursor: pointer;
  transition: background-image 0.5s ease;
  /* border: 1px solid var(--themeColor); */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: box-shadow 0.3s, transform 0.3s;
}
.role-title a {
  color: white !important;
  text-decoration: none;
}
.role-title-button:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  transform: translateY(-1rem);
}

.type-title {
  width: 17rem;
  text-align: right;
}
</style>
<style scoped>
@media screen and (min-width: 1080px) {
  .page {
    /* padding: 3rem 4rem; */
    border: none;
  }
}
/* @media screen and (max-width: 1080px) {
  .d-flex-center-between,
  .d-flex-center-middle {
    display: block;
    text-align: center;
  }
  .page {
    border-top: 1px solid #efefef;
  }
} */
@media screen and (max-width: 1080px) {
  .exam-title,
  .role-title,
  .exam-title-small {
    color: var(--themeColor);
    font-weight: 700;
    font-size: 4.5rem;
  }
  .page {
    padding: 1rem 0;
  }
  .d-flex-center-between,
  .d-flex-center-middle {
    display: block;
    text-align: center;
  }
  .type-title {
    height: 5rem;
    text-align: center;
    width: auto;
  }
  .tab-details {
    display: block;
    text-align: center;
  }
  .details-list {
    margin-bottom: 1rem;
  }
  .detail-content {
    width: 100%;
    margin: 0;
  }
  .stories-small-title {
    text-align: left;
  }
}
</style>
